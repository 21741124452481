/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BinaryFileType } from '../models/binary-file-type';
import { externalGetGateDeliveryNotePdf } from '../fn/external/external-get-gate-delivery-note-pdf';
import { ExternalGetGateDeliveryNotePdf$Params } from '../fn/external/external-get-gate-delivery-note-pdf';
import { externalGetGateDeliveryNotePdfs } from '../fn/external/external-get-gate-delivery-note-pdfs';
import { ExternalGetGateDeliveryNotePdfs$Params } from '../fn/external/external-get-gate-delivery-note-pdfs';
import { externalGetPdfForSignature } from '../fn/external/external-get-pdf-for-signature';
import { ExternalGetPdfForSignature$Params } from '../fn/external/external-get-pdf-for-signature';
import { externalGetSingleAttachmentForSignature$Heic } from '../fn/external/external-get-single-attachment-for-signature-heic';
import { ExternalGetSingleAttachmentForSignature$Heic$Params } from '../fn/external/external-get-single-attachment-for-signature-heic';
import { externalGetSingleAttachmentForSignature$Heif } from '../fn/external/external-get-single-attachment-for-signature-heif';
import { ExternalGetSingleAttachmentForSignature$Heif$Params } from '../fn/external/external-get-single-attachment-for-signature-heif';
import { externalGetSingleAttachmentForSignature$Jpeg } from '../fn/external/external-get-single-attachment-for-signature-jpeg';
import { ExternalGetSingleAttachmentForSignature$Jpeg$Params } from '../fn/external/external-get-single-attachment-for-signature-jpeg';
import { externalGetSingleAttachmentForSignature$Pdf } from '../fn/external/external-get-single-attachment-for-signature-pdf';
import { ExternalGetSingleAttachmentForSignature$Pdf$Params } from '../fn/external/external-get-single-attachment-for-signature-pdf';
import { externalGetSingleAttachmentForSignature$Png } from '../fn/external/external-get-single-attachment-for-signature-png';
import { ExternalGetSingleAttachmentForSignature$Png$Params } from '../fn/external/external-get-single-attachment-for-signature-png';
import { externalGetSinglePdfForSignature } from '../fn/external/external-get-single-pdf-for-signature';
import { ExternalGetSinglePdfForSignature$Params } from '../fn/external/external-get-single-pdf-for-signature';
import { externalGetTourGateDeliveryNotePdf } from '../fn/external/external-get-tour-gate-delivery-note-pdf';
import { ExternalGetTourGateDeliveryNotePdf$Params } from '../fn/external/external-get-tour-gate-delivery-note-pdf';
import { externalGetTourGateDeliveryNotePdfs } from '../fn/external/external-get-tour-gate-delivery-note-pdfs';
import { ExternalGetTourGateDeliveryNotePdfs$Params } from '../fn/external/external-get-tour-gate-delivery-note-pdfs';
import { getCarrierDeliveryNoteCombinedPdf } from '../fn/delivery-notes/get-carrier-delivery-note-combined-pdf';
import { GetCarrierDeliveryNoteCombinedPdf$Params } from '../fn/delivery-notes/get-carrier-delivery-note-combined-pdf';
import { getCarrierDeliveryNotePdf } from '../fn/delivery-notes/get-carrier-delivery-note-pdf';
import { GetCarrierDeliveryNotePdf$Params } from '../fn/delivery-notes/get-carrier-delivery-note-pdf';
import { getCarrierDeliveryNotePdfsForBundle } from '../fn/delivery-notes/get-carrier-delivery-note-pdfs-for-bundle';
import { GetCarrierDeliveryNotePdfsForBundle$Params } from '../fn/delivery-notes/get-carrier-delivery-note-pdfs-for-bundle';
import { getConsigneeDeliveryNoteCombinedPdf } from '../fn/consignee/get-consignee-delivery-note-combined-pdf';
import { GetConsigneeDeliveryNoteCombinedPdf$Params } from '../fn/consignee/get-consignee-delivery-note-combined-pdf';
import { getConsigneeDeliveryNoteFromPool } from '../fn/delivery-notes/get-consignee-delivery-note-from-pool';
import { GetConsigneeDeliveryNoteFromPool$Params } from '../fn/delivery-notes/get-consignee-delivery-note-from-pool';
import { getConsigneeDeliveryNotePdfsForBundle } from '../fn/delivery-notes/get-consignee-delivery-note-pdfs-for-bundle';
import { GetConsigneeDeliveryNotePdfsForBundle$Params } from '../fn/delivery-notes/get-consignee-delivery-note-pdfs-for-bundle';
import { getConsigneeSelfCheckinLegalDocument } from '../fn/pdf/get-consignee-self-checkin-legal-document';
import { GetConsigneeSelfCheckinLegalDocument$Params } from '../fn/pdf/get-consignee-self-checkin-legal-document';
import { getConsignorDeliveryNoteCombinedPdf } from '../fn/delivery-notes/get-consignor-delivery-note-combined-pdf';
import { GetConsignorDeliveryNoteCombinedPdf$Params } from '../fn/delivery-notes/get-consignor-delivery-note-combined-pdf';
import { getDeliveryNoteFromPool } from '../fn/delivery-notes/get-delivery-note-from-pool';
import { GetDeliveryNoteFromPool$Params } from '../fn/delivery-notes/get-delivery-note-from-pool';
import { getDeliveryNotePdfsForBundle } from '../fn/delivery-notes/get-delivery-note-pdfs-for-bundle';
import { GetDeliveryNotePdfsForBundle$Params } from '../fn/delivery-notes/get-delivery-note-pdfs-for-bundle';
import { getExternalDeliveryNoteCompletePdf } from '../fn/external/get-external-delivery-note-complete-pdf';
import { GetExternalDeliveryNoteCompletePdf$Params } from '../fn/external/get-external-delivery-note-complete-pdf';


/**
 * Endpoints for PDF file acces and upload
 */
@Injectable({ providedIn: 'root' })
export class PdfService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCarrierDeliveryNotePdfsForBundle()` */
  static readonly GetCarrierDeliveryNotePdfsForBundlePath = '/organization-sites/{organizationSiteKey}/carrier/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-note-pdfs';

  /**
   * Get bundle PDF as carrier.
   *
   * Get all delivery note PDF's as merged PDF from carrier delivery
   * note bundle. If the delivery note bundle does not have any delivery
   * notes an HTTP status code 400 is returned.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarrierDeliveryNotePdfsForBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierDeliveryNotePdfsForBundle$Response(params: GetCarrierDeliveryNotePdfsForBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getCarrierDeliveryNotePdfsForBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle PDF as carrier.
   *
   * Get all delivery note PDF's as merged PDF from carrier delivery
   * note bundle. If the delivery note bundle does not have any delivery
   * notes an HTTP status code 400 is returned.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarrierDeliveryNotePdfsForBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierDeliveryNotePdfsForBundle(params: GetCarrierDeliveryNotePdfsForBundle$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getCarrierDeliveryNotePdfsForBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getCarrierDeliveryNotePdf()` */
  static readonly GetCarrierDeliveryNotePdfPath = '/organization-sites/{organizationSiteKey}/carrier/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-notes/{deliveryNoteKey}/pdf';

  /**
   * Get PDF as carrier.
   *
   * Get the PDF of a carrier delivery note.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarrierDeliveryNotePdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierDeliveryNotePdf$Response(params: GetCarrierDeliveryNotePdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getCarrierDeliveryNotePdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PDF as carrier.
   *
   * Get the PDF of a carrier delivery note.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarrierDeliveryNotePdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierDeliveryNotePdf(params: GetCarrierDeliveryNotePdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getCarrierDeliveryNotePdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getConsigneeDeliveryNoteFromPool()` */
  static readonly GetConsigneeDeliveryNoteFromPoolPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/pdf';

  /**
   * Get PDF as consignee.
   *
   * Get a delivery note PDF from the consignee delivery note pool.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDeliveryNoteFromPool()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNoteFromPool$Response(params: GetConsigneeDeliveryNoteFromPool$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDeliveryNoteFromPool(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PDF as consignee.
   *
   * Get a delivery note PDF from the consignee delivery note pool.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDeliveryNoteFromPool$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNoteFromPool(params: GetConsigneeDeliveryNoteFromPool$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDeliveryNoteFromPool$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getConsigneeDeliveryNotePdfsForBundle()` */
  static readonly GetConsigneeDeliveryNotePdfsForBundlePath = '/organization-sites/{organizationSiteKey}/consignee/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-note-pdfs';

  /**
   * Get bundle PDF as consignee.
   *
   * Get all delivery note PDF's as merged PDF from consignee delivery
   * note bundle. If the delivery note bundle does not have any delivery
   * notes an HTTP status code 400 is returned.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDeliveryNotePdfsForBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNotePdfsForBundle$Response(params: GetConsigneeDeliveryNotePdfsForBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDeliveryNotePdfsForBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle PDF as consignee.
   *
   * Get all delivery note PDF's as merged PDF from consignee delivery
   * note bundle. If the delivery note bundle does not have any delivery
   * notes an HTTP status code 400 is returned.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDeliveryNotePdfsForBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNotePdfsForBundle(params: GetConsigneeDeliveryNotePdfsForBundle$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDeliveryNotePdfsForBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getDeliveryNotePdfsForBundle()` */
  static readonly GetDeliveryNotePdfsForBundlePath = '/organization-sites/{organizationSiteKey}/consignor/delivery-note-bundles/{deliveryNoteBundleKey}/delivery-note-pdfs';

  /**
   * Get bundle PDF from bundle.
   *
   * Returns a PDF file containing all delivery notes associated with a specific delivery note bundle.
   * If the delivery note bundle does not have any delivery note an HTTP
   * status code 400 is returned.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeliveryNotePdfsForBundle()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryNotePdfsForBundle$Response(params: GetDeliveryNotePdfsForBundle$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getDeliveryNotePdfsForBundle(this.http, this.rootUrl, params, context);
  }

  /**
   * Get bundle PDF from bundle.
   *
   * Returns a PDF file containing all delivery notes associated with a specific delivery note bundle.
   * If the delivery note bundle does not have any delivery note an HTTP
   * status code 400 is returned.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeliveryNotePdfsForBundle$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryNotePdfsForBundle(params: GetDeliveryNotePdfsForBundle$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getDeliveryNotePdfsForBundle$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getDeliveryNoteFromPool()` */
  static readonly GetDeliveryNoteFromPoolPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-notes/{deliveryNoteKey}/pdf';

  /**
   * Get PDF from pool.
   *
   * Get delivery note PDF from delivery note pool.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeliveryNoteFromPool()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryNoteFromPool$Response(params: GetDeliveryNoteFromPool$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getDeliveryNoteFromPool(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PDF from pool.
   *
   * Get delivery note PDF from delivery note pool.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDeliveryNoteFromPool$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeliveryNoteFromPool(params: GetDeliveryNoteFromPool$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getDeliveryNoteFromPool$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getConsignorDeliveryNoteCombinedPdf()` */
  static readonly GetConsignorDeliveryNoteCombinedPdfPath = '/organization-sites/{organizationSiteKey}/consignor/delivery-notes/{deliveryNoteKey}/complete-pdf';

  /**
   * Get a complete pdf with checkout and checkin data.
   *
   * Get a complete pdf with checkout and checkin data: the delivery note,
   * all attachments, all signatures and all images as a consignor
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsignorDeliveryNoteCombinedPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsignorDeliveryNoteCombinedPdf$Response(params: GetConsignorDeliveryNoteCombinedPdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsignorDeliveryNoteCombinedPdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a complete pdf with checkout and checkin data.
   *
   * Get a complete pdf with checkout and checkin data: the delivery note,
   * all attachments, all signatures and all images as a consignor
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsignorDeliveryNoteCombinedPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsignorDeliveryNoteCombinedPdf(params: GetConsignorDeliveryNoteCombinedPdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsignorDeliveryNoteCombinedPdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getCarrierDeliveryNoteCombinedPdf()` */
  static readonly GetCarrierDeliveryNoteCombinedPdfPath = '/organization-sites/{organizationSiteKey}/carrier/delivery-notes/{deliveryNoteKey}/complete-pdf';

  /**
   * Get a complete pdf with checkout and checkin data.
   *
   * Get a complete pdf with checkout and checkin data: the delivery note,
   * all attachments, all signatures and all images as a carrier
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCarrierDeliveryNoteCombinedPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierDeliveryNoteCombinedPdf$Response(params: GetCarrierDeliveryNoteCombinedPdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getCarrierDeliveryNoteCombinedPdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a complete pdf with checkout and checkin data.
   *
   * Get a complete pdf with checkout and checkin data: the delivery note,
   * all attachments, all signatures and all images as a carrier
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCarrierDeliveryNoteCombinedPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCarrierDeliveryNoteCombinedPdf(params: GetCarrierDeliveryNoteCombinedPdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getCarrierDeliveryNoteCombinedPdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getConsigneeDeliveryNoteCombinedPdf()` */
  static readonly GetConsigneeDeliveryNoteCombinedPdfPath = '/organization-sites/{organizationSiteKey}/consignee/delivery-notes/{deliveryNoteKey}/complete-pdf';

  /**
   * Get a complete pdf with checkout and checkin data.
   *
   * Get a complete pdf with checkout and checkin data: the delivery note,
   * all attachments, all signatures and all images
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeDeliveryNoteCombinedPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNoteCombinedPdf$Response(params: GetConsigneeDeliveryNoteCombinedPdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeDeliveryNoteCombinedPdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a complete pdf with checkout and checkin data.
   *
   * Get a complete pdf with checkout and checkin data: the delivery note,
   * all attachments, all signatures and all images
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeDeliveryNoteCombinedPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeDeliveryNoteCombinedPdf(params: GetConsigneeDeliveryNoteCombinedPdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeDeliveryNoteCombinedPdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetPdfForSignature()` */
  static readonly ExternalGetPdfForSignaturePath = '/external/signature-pads/{signaturePadType}/bundle-data/pdf';

  /**
   * Get all delivery note PDFs as one pdf for signature pad.
   *
   * Return PDF with all delivery note pdfs from the checkout
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetPdfForSignature()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetPdfForSignature$Response(params: ExternalGetPdfForSignature$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetPdfForSignature(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all delivery note PDFs as one pdf for signature pad.
   *
   * Return PDF with all delivery note pdfs from the checkout
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetPdfForSignature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetPdfForSignature(params: ExternalGetPdfForSignature$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetPdfForSignature$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetSinglePdfForSignature()` */
  static readonly ExternalGetSinglePdfForSignaturePath = '/external/signature-pads/checkin/delivery-note/{deliveryNoteKey}/pdf';

  /**
   * Get a delivery note PDF by deliveryNoteKey.
   *
   * Return a PDF for a deliveryNoteKey for the checkin with carrier access key
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSinglePdfForSignature()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSinglePdfForSignature$Response(params: ExternalGetSinglePdfForSignature$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSinglePdfForSignature(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a delivery note PDF by deliveryNoteKey.
   *
   * Return a PDF for a deliveryNoteKey for the checkin with carrier access key
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSinglePdfForSignature$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSinglePdfForSignature(params: ExternalGetSinglePdfForSignature$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSinglePdfForSignature$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetSingleAttachmentForSignature()` */
  static readonly ExternalGetSingleAttachmentForSignaturePath = '/external/signature-pads/checkin/delivery-note/{deliveryNoteKey}/attachments/{attachmentKey}';

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Pdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Pdf$Response(params: ExternalGetSingleAttachmentForSignature$Pdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Pdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Pdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Pdf(params: ExternalGetSingleAttachmentForSignature$Pdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Pdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Heic()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Heic$Response(params: ExternalGetSingleAttachmentForSignature$Heic$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Heic(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Heic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Heic(params: ExternalGetSingleAttachmentForSignature$Heic$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Heic$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Heif()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Heif$Response(params: ExternalGetSingleAttachmentForSignature$Heif$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Heif(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Heif$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Heif(params: ExternalGetSingleAttachmentForSignature$Heif$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Heif$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Jpeg()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Jpeg$Response(params: ExternalGetSingleAttachmentForSignature$Jpeg$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Jpeg(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Jpeg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Jpeg(params: ExternalGetSingleAttachmentForSignature$Jpeg$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Jpeg$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetSingleAttachmentForSignature$Png()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Png$Response(params: ExternalGetSingleAttachmentForSignature$Png$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetSingleAttachmentForSignature$Png(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a attachment by the attachmentKey for a checkin.
   *
   * Return a attachment by the attachmentKey for the checkin with carrier access key and the deliveryNoteKey
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetSingleAttachmentForSignature$Png$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetSingleAttachmentForSignature$Png(params: ExternalGetSingleAttachmentForSignature$Png$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetSingleAttachmentForSignature$Png$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetGateDeliveryNotePdf()` */
  static readonly ExternalGetGateDeliveryNotePdfPath = '/external/gate/delivery-notes/{deliveryNoteKey}/pdf';

  /**
   * Get PDF for gate page.
   *
   * Get a single delivery note PDF from a gate (checkout).
   * The token is the token from the gate url.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetGateDeliveryNotePdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetGateDeliveryNotePdf$Response(params: ExternalGetGateDeliveryNotePdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetGateDeliveryNotePdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PDF for gate page.
   *
   * Get a single delivery note PDF from a gate (checkout).
   * The token is the token from the gate url.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetGateDeliveryNotePdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetGateDeliveryNotePdf(params: ExternalGetGateDeliveryNotePdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetGateDeliveryNotePdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetTourGateDeliveryNotePdf()` */
  static readonly ExternalGetTourGateDeliveryNotePdfPath = '/external/tour/gate/delivery-notes/{deliveryNoteKey}/pdf';

  /**
   * Get PDF for tour gate page.
   *
   * Get a single delivery note PDF from a tour gate (checkout).
   * The token is the token from the gate url.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetTourGateDeliveryNotePdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourGateDeliveryNotePdf$Response(params: ExternalGetTourGateDeliveryNotePdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetTourGateDeliveryNotePdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get PDF for tour gate page.
   *
   * Get a single delivery note PDF from a tour gate (checkout).
   * The token is the token from the gate url.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetTourGateDeliveryNotePdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourGateDeliveryNotePdf(params: ExternalGetTourGateDeliveryNotePdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetTourGateDeliveryNotePdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetGateDeliveryNotePdfs()` */
  static readonly ExternalGetGateDeliveryNotePdfsPath = '/external/gate/delivery-note-pdfs';

  /**
   * Get all delivery note PDFs as one PDF.
   *
   * Get all delivery note PDF's from a gate (checkout).
   * The token is the token from the gate URL.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetGateDeliveryNotePdfs()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetGateDeliveryNotePdfs$Response(params: ExternalGetGateDeliveryNotePdfs$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetGateDeliveryNotePdfs(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all delivery note PDFs as one PDF.
   *
   * Get all delivery note PDF's from a gate (checkout).
   * The token is the token from the gate URL.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetGateDeliveryNotePdfs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetGateDeliveryNotePdfs(params: ExternalGetGateDeliveryNotePdfs$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetGateDeliveryNotePdfs$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `externalGetTourGateDeliveryNotePdfs()` */
  static readonly ExternalGetTourGateDeliveryNotePdfsPath = '/external/tour/gate/delivery-note-pdfs';

  /**
   * Get all delivery note PDFs as one PDF for a tour gate.
   *
   * Get all delivery note PDF's from a tour gate (checkout).
   * The token is the token from the gate URL.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalGetTourGateDeliveryNotePdfs()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourGateDeliveryNotePdfs$Response(params: ExternalGetTourGateDeliveryNotePdfs$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return externalGetTourGateDeliveryNotePdfs(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all delivery note PDFs as one PDF for a tour gate.
   *
   * Get all delivery note PDF's from a tour gate (checkout).
   * The token is the token from the gate URL.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalGetTourGateDeliveryNotePdfs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalGetTourGateDeliveryNotePdfs(params: ExternalGetTourGateDeliveryNotePdfs$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.externalGetTourGateDeliveryNotePdfs$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getExternalDeliveryNoteCompletePdf()` */
  static readonly GetExternalDeliveryNoteCompletePdfPath = '/external/gate/delivery-notes/{deliveryNoteKey}/complete-pdf';

  /**
   * Get a complete sanitized pdf with checkout and checkin data.
   *
   * Get a complete sanitized pdf with checkout and checkin data: the delivery note,
   * all attachments and all images
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExternalDeliveryNoteCompletePdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalDeliveryNoteCompletePdf$Response(params: GetExternalDeliveryNoteCompletePdf$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getExternalDeliveryNoteCompletePdf(this.http, this.rootUrl, params, context);
  }

  /**
   * Get a complete sanitized pdf with checkout and checkin data.
   *
   * Get a complete sanitized pdf with checkout and checkin data: the delivery note,
   * all attachments and all images
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExternalDeliveryNoteCompletePdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExternalDeliveryNoteCompletePdf(params: GetExternalDeliveryNoteCompletePdf$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getExternalDeliveryNoteCompletePdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

  /** Path part for operation `getConsigneeSelfCheckinLegalDocument()` */
  static readonly GetConsigneeSelfCheckinLegalDocumentPath = '/organization-sites/{organizationSiteKey}/self-checkin/legal-document';

  /**
   * Get the legal PDF document.
   *
   * Retrieve the legal PDF document associated with the organization
   * site. The file name is returned in the content-disposition section
   * of the header.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getConsigneeSelfCheckinLegalDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeSelfCheckinLegalDocument$Response(params: GetConsigneeSelfCheckinLegalDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<BinaryFileType>> {
    return getConsigneeSelfCheckinLegalDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the legal PDF document.
   *
   * Retrieve the legal PDF document associated with the organization
   * site. The file name is returned in the content-disposition section
   * of the header.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getConsigneeSelfCheckinLegalDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getConsigneeSelfCheckinLegalDocument(params: GetConsigneeSelfCheckinLegalDocument$Params, context?: HttpContext): Observable<BinaryFileType> {
    return this.getConsigneeSelfCheckinLegalDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<BinaryFileType>): BinaryFileType => r.body)
    );
  }

}
